<template>
  <div class="xui-editor" :class="{ 'xui-editor_active': editor.isFocused }">
    <bubble-menu :editor="editor" :tippy-options="{ placement: 'top' }" :should-show="shouldShowBubble" class="sw-editor__bubble sw-editor__bubble_active" ref="bubble">
      <button
        class="sw-editor__bubble-item"
        :class="{ 'sw-editor__bubble-item_active': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <bold-icon/>
      </button>

      <button
        class="sw-editor__bubble-item"
        :class="{ 'sw-editor__bubble-item_active': editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <underline-icon/>
      </button>

      <button
        class="sw-editor__bubble-item"
        :class="{ 'sw-editor__bubble-item_active': editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <stroke-icon/>
      </button>

      <button
        class="sw-editor__bubble-item"
        :class="{ 'sw-editor__bubble-item_active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <italic-icon/>
      </button>
    </bubble-menu>

    <table-cell-popover :editor="editor" v-if="editor" @created="tableCellPopoverCreated"/>
    <table-popover :editor="editor" v-if="editor" @created="tablePopoverCreated"/>
    <editor-content class="xui-pros-mirror" :editor="editor" />

    <suggestion-popover :suggestion="suggestion" @select="selectSuggestion" ref="suggestions"/>
    <overlay-gallery :src="gallery.src" v-if="gallery.open" @close="gallery.open = false"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {Editor, EditorContent, BubbleMenu, VueNodeViewRenderer} from '@tiptap/vue-2'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Emoji from "@/components/ui/textEditor/emoji"

import UnderlineIcon from "@/components/icons/editor/UnderlineIcon"
import BoldIcon from "@/components/icons/editor/BoldIcon"
import StrokeIcon from "@/components/icons/editor/StrokeIcon"
import ItalicIcon from "@/components/icons/editor/ItalicIcon"
import withSuggestions from "@/components/ui/textEditor/withSuggestions"
import withEmojis from "@/components/ui/textEditor/withEmojis"
import editorMixin from '@/components/ui/mixins/editorMixin'
import {isTextSelection} from "@tiptap/core";
import suggestion from "@/components/ui/textEditor/suggestion"
import MemberList from "@/components/ui/textEditor/MemberList"
import { Table } from "@tiptap/extension-table"
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TableView from '@/components/ui/textEditor/tables/TableView'
import Focus from '@tiptap/extension-focus'
import TableCellView from "@/components/ui/textEditor/tables/TableCellView"
import TableCellPopover from "@/components/ui/textEditor/popovers/TableCellPopover";
import TablePopover from "@/components/ui/textEditor/popovers/TablePopover";
import FloatingMenuExtension from "@/components/ui/textEditor/floatingMenu/extension"


/* eslint-disable */
export default {
  name: 'TextEditor',

  components: {
    TablePopover,
    TableCellPopover,
    EditorContent,
    BubbleMenu,
    UnderlineIcon,
    BoldIcon,
    StrokeIcon,
    ItalicIcon
  },

  mixins: [withSuggestions, withEmojis, editorMixin],

  props: {
    value: {
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    extensions: {
      type: Array,
      default: () => []
    },

    contentType: {
      type: String,
      default: 'json'
    },

    withAttaches: {
      type: Boolean,
      default: false,
    },

    useDefaultHistory: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    const self = this

    const editor = new Editor({
      extensions:  [
        ...this.extensions,
        ...this.getCommonExtensions({
          useHistory: this.useDefaultHistory,
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          placeholder: 'Введите описание, либо введите / чтобы увидеть опции',
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        FloatingMenuExtension.configure({}),
        Emoji,
        Focus,
        Mention.configure({
          suggestion: suggestion({
            getMembers: () => this.getMembers(),
            getLabel: (member) => `${member.first_name} ${member.last_name}`,
            idField: 'member_id',
            listComponent: MemberList,
          })
        }),
        TableHeader.extend({
          addOptions() {
            return {
              ...this.parent?.(),
              getPopover: () => {
                return self.tableCellPopover
              },
            }
          },
          addNodeView() {
            return VueNodeViewRenderer(TableCellView)
          },
        }),
        TableCell.extend({
          addOptions() {
            return {
              ...this.parent?.(),
              getPopover: () => {
                return self.tableCellPopover
              },
            }
          },
          addNodeView() {
            return VueNodeViewRenderer(TableCellView)
          },
        }),
        TableRow,
        Table.extend({
          addOptions() {
            return {
              ...this.parent?.(),
              getPopover: () => {
                return self.tablePopover
              },
            }
          },
          addNodeView() {
            return VueNodeViewRenderer(TableView)
          },
        }).configure({
          HTMLAttributes: {
            class: 'my-custom-class',
          },
          resizable: true,
        })
      ],
      onUpdate: ({ editor, transaction }) => {
        const { meta } = transaction

        if (meta['y-sync$']) {
          return
        }

        this.$emit('input', { getJSON: () => editor.getJSON(), getHTML: () => editor.getHTML() })
      },
      content: this.value,
    })

    editor.on('create', ({ editor }) => {
      this.listenImageOpening(editor)
    })

    return {
      editor,
      emojiIndex: this.newEmojiIndex(),
      showMenu: false,
      forceShow: false,
      tableCellPopover: null,
      tablePopover: null,
    }
  },

  computed: {
    ...mapGetters(['activeMembers']),

    isMenuActive () {
      return this.editor.isFocused || this.showMenu
    },

    suggestionEl () {
      return this.$refs.suggestions.$el
    }
  },

  methods: {
    shouldShowBubble ({ view, state, from, to })  {
      const { doc, selection } = state
      const { empty } = selection

      const isImage = selection?.node?.type.name === 'image'

      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)

      // When clicking on a element inside the bubble menu the editor "blur" event
      // is called and the bubble menu item is focussed. In this case we should
      // consider the menu as part of the editor and keep showing the menu
      const isChildOfMenu = this.$refs.bubble.$el.contains(document.activeElement)

      const hasEditorFocus = view.hasFocus() || isChildOfMenu

      if (!hasEditorFocus || empty || isImage|| isEmptyTextBlock || !this.editor.isEditable) {
        return false
      }

      return true
    },

    getMembers () {
      return this.activeMembers
    },

    onAttachClick () {
      this.$emit('attach-file')
    },

    tableCellPopoverCreated (popover) {
      this.tableCellPopover = popover
    },

    tablePopoverCreated (popover) {
      this.tablePopover = popover
    }
  },

  mounted() {
    //this.listenImageOpening(this.editor)
    this.$emit('init', { editor: this.editor })
  },

  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>
<style lang="sass">
.collaboration-cursor__caret
  border-left: 1px solid #0d0d0d
  border-right: 1px solid #0d0d0d
  margin-left: -1px
  margin-right: -1px
  pointer-events: none
  position: relative
  word-break: normal

/* Render the username above the caret */
.collaboration-cursor__label
  border-radius: 3px 3px 3px 0
  color: #0d0d0d
  font-size: 12px
  font-style: normal
  font-weight: 600
  left: -1px
  line-height: normal
  padding: 0.1rem 0.3rem
  position: absolute
  top: -1.4em
  user-select: none
  white-space: nowrap
</style>
