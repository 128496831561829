<template>
  <div class="score-selector">
    <div v-if="showLabel" class="score-selector__label">
      {{ $t('taskStats.prediction.yourScore') }}
    </div>

    <div class="score-selector__fields">
      <el-tooltip
        :content="v$.form.hours.$errors.length ? v$.form.hours.$errors[0].$message : ''"
        placement="top"
        manual
        popper-class="validate_tooltip"
        :value="v$.form.hours.$error"
      >
        <round-text-field
          v-model.number="form.hours"
          :label="$t('tasks.hours')"
          :valid="!v$.form.hours.$error"
          label-position="bottom"
          class="score-selector__input"
          @input="onFieldChange"
        />
      </el-tooltip>
      <el-tooltip
        :content="v$.form.minutes.$errors.length ? v$.form.minutes.$errors[0].$message : ''"
        placement="top"
        manual
        popper-class="validate_tooltip"
        :value="v$.form.minutes.$error"
      >
        <round-text-field
          v-model.number="form.minutes"
          :label="$t('tasks.minutes')"
          :valid="!v$.form.minutes.$error"
          label-position="bottom"
          class="score-selector__input"
          @input="onFieldChange"
        />
      </el-tooltip>
    </div>

    <ui-button @click.prevent="onChange" ui-class="block size_sm" class="score-selector__btn">
      {{ $t('common.save') }}
    </ui-button>
  </div>
</template>

<script>
  import RoundTextField from "../../ui/RoundTextField"
  import UiButton from 'scorework-ui/src/components/UiButton'
  import { getScoreObject, MaxScoreValue, MinScoreValue } from '@/services/scoring'
  import useVuelidate  from '@vuelidate/core'
  import {
    requiredIf,
    helpers,
    integer,
  } from '@vuelidate/validators'

  import { mapGetters } from 'vuex'

  export default {
    name: 'ScoreSelector',

    components: { RoundTextField, UiButton },

    props: {
      value: {
        required: true
      },

      showLabel: {
        type: Boolean,
        default: false,
      }
    },

    setup () {
      return { v$: useVuelidate() }
    },

    data () {
      return {
        form: {
          days: 0,
          hours: 0,
          minutes: 0
        }
      }
    },

    validations () {
      return {
        form: {
          hours: {
            $autoDirty: true,
            requiredIf: helpers.withMessage(this.$t('validation.required'), requiredIf(!!this.minutes)),
            integer: helpers.withMessage(this.$t('validation.integer'), integer),
            minValue: helpers.withMessage(
              this.$t('validation.minScoreValue', { minutes: MinScoreValue }),
              () => {
                const calculated = this.calculateValue()

                return calculated >= MinScoreValue
            }),
            maxValue: helpers.withMessage(
              this.$t('validation.maxScoreValue', { hours: MaxScoreValue / 60 }),
              () => {
                const calculated = this.calculateValue()

                return calculated <= MaxScoreValue
            }),
          },
          minutes: {
            $autoDirty: true,
            requiredIf: helpers.withMessage(this.$t('validation.required'), requiredIf(!!this.hours)),
            integer: helpers.withMessage(this.$t('validation.integer'), integer),
          },
        }
      }
    },

    computed: {
      ...mapGetters(['workspace']),

      parsedValue () {
        return getScoreObject(this.value)
      }
    },

    methods: {
      async onChange () {
        this.v$.$reset()
        const isFormCorrect = await this.v$.$validate()

        if (isFormCorrect) {
          this.$emit('input', this.calculateValue())
        }
      },

      initValue () {
        this.form = { ...this.parsedValue }
      },

      calculateValue () {
        return this.form.days * this.workspace.daily_minutes + this.form.hours * 60 + this.form.minutes
      },

      onFieldChange () {
        this.$emit('change', this.calculateValue())
      }
    },

    created () {
      this.initValue()
    }
  }
</script>

<style lang="sass">
  .validate_tooltip
    background: #FF5555 !important
    &.el-tooltip__popper[x-placement^=top] .popper__arrow
      border-top-color: #FF5555 !important
    &.el-tooltip__popper[x-placement^=top] .popper__arrow::after
      border-top-color: #FF5555 !important
</style>
