<template>
  <div class="pages-layout">
    <div class="pages-sidebar">
      <div class="pages-sidebar__header">
        <ui-button ui-class="light block size_md" class="page-create-btn" @click="onCreateClick">
          <plus/> {{ $t('knowledge.createPage') }}
        </ui-button>
      </div>

      <pages-menu/>
    </div>

    <router-view :key="routerKey"/>
  </div>
</template>
<script>
  import UiButton from 'scorework-ui/src/components/UiButton'
  import Plus from '@/components/icons/Plus'
  import PagesMenu from '@/components/pages/PagesMenu'
  import socketService from '@/services/socket'
  import pagesSocket from '@/store/socket/pages'

  export default {
    name: 'Pages',

    components: {PagesMenu, Plus, UiButton },

    data () {
      return {
        socket: null,
      }
    },

    computed: {
      routerKey () {
        const params = this.$route.params
          ? Object.keys(this.$route.params).map((key) => `${key}=${this.$route.params[key]}`).join('&')
          : ''

        const query = this.$route.query
          ? Object.keys(this.$route.query).map((key) => `${key}=${this.$route.query[key]}`).join('&')
          : ''

        return `${this.$route.name}:${params}${query}`
      }
    },

    methods: {
      getPageIcon (icon) {
        const iconParts = icon.split(':')

        return iconParts[1]
      },

      onAttachFile () {

      },

      onEditorInit () {

      },

      onCreateClick () {
        this.$router.push({ name: 'pages.create' })
      }
    },

    created () {
      this.socket = pagesSocket(this.$store, () => {})
      this.socket.subscribe(socketService.socket)
    },

    beforeDestroy () {
      this.socket.unsubscribe(socketService.socket)
    }
  }
</script>
<style lang="sass">
  .pages-layout
    display: flex
    height: 100%
    width: 100%
    background: var(--block-bg-color)

  .pages
    &-sidebar
      width: 260px
      height: 100%
      border-right: 1px solid var(--block-border-bg)
      display: flex
      flex-flow: column

      &__header
        padding: 20px 16px 16px
        border-bottom: 1px solid var(--block-border-bg)

    &-content
      flex: 1
      height: 100%
      display: flex
      flex-flow: column

      &__header
        display: flex
        justify-content: space-between
        padding: 20px 20px

        &-title
          font-size: 20px
          font-weight: 600
          margin-bottom: 4px

      &__wrapper
        flex: 1
        overflow: scroll
        padding: 24px 0

  .page-create-btn
    display: flex
    column-gap: 8px
    align-items: center
</style>
